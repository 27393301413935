.modal {
  display: none;
  position: fixed;
  z-index: 2;
  /* padding-top: 100px; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
  text-align: center;
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 92%;
  max-height: 92%;
  margin-top: 1%;
  margin-bottom: 1%;
  /* width: 80%; */
  /* max-width: 700px; */
}

.caption {
  color: #FFF;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-true {
  display: initial;
}

.modal-false {
  display: none;
}

.modal-content {
  cursor: pointer;
}

.modal-content, .caption {
    animation-name: zoom;
    animation-duration: 0.4s;
}

@keyframes zoom {
    from {
      transform:scale(0.8)
    }
    to {
      transform:scale(1)
    }
}
