.pane {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 95%;
  margin: 0 auto;
}

.pane-vert {
  align-self: center;
  padding: 10px;
}

/* .pane-vert a {
  max-width: 100%;
} */

.pane-vert img {
  padding: 0;
  max-width: 100%;
}

.pane-stack-outer {
  align-self: center;
  margin: 0 auto;
  padding: 0;
  max-width: 51.2%;
  padding: 3px;
}

.pane-stack {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}

/* .pane-stack a {
  max-width: 100%;
} */

.pane-stack img {
  max-width: 100%;
  margin: 3px;
}

.pane img {
  pointer-events: all;
  box-shadow: 0px 10px 15px #888888;

  -webkit-transition: box-shadow 0.5s ease-in-out;
     -moz-transition: box-shadow 0.5s ease-in-out;
       -o-transition: box-shadow 0.5s ease-in-out;
      -ms-transition: box-shadow 0.5s ease-in-out;
          transition: box-shadow 0.5s ease-in-out;
}

.pane img:hover {
  cursor: pointer;
  box-shadow: 0px 10px 15px #1f1f1f;
}

.center {
  margin-bottom: 40px;
}

.spinner-placeholder {
  width: 80px;
  height: 80px;
}

@media (min-width: 1350px) {
  .pane {
    /* border: 1px solid red; */
    max-width: 65%;
  }
}
